import { ApiEndpoints } from "@/configs";
import { useFetch, usePost, useUpdate } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  AddPricingPayload,
  Countries,
  PaginatedPricingList,
  PaginatedProviderAccounts,
  Pricing,
  PricingPayload,
  Services,
} from "utilities";

export const useGetPricings = ({
  page,
  search,
  country = "",
  service = "",
  provider_id = "",
}: {
  page?: number;
  search?: string;
  country?: string;
  service?: string;
  provider_id?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
    country,
    service,
    provider_id,
  });

  return useFetch<PaginatedPricingList>(ApiEndpoints.pricing, params);
};

export const useUpdatePricing = ({ id }: { id: string }) =>
  useUpdate<Partial<PricingPayload>, Pricing>(`${ApiEndpoints.pricing}/${id}`);

export const useAddPricing = () =>
  usePost<AddPricingPayload, Pricing>(`${ApiEndpoints.pricing}`);

export const useGetPricingCountries = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Countries>(`${ApiEndpoints.countries}`, params);
};

export const useGetPricingServices = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Services>(`${ApiEndpoints.services}`, params);
};

export const useGetProviders = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedProviderAccounts>(
    `${ApiEndpoints.providersInstance}`,
    params,
  );
};

export const useUpdatePricingStatus = ({ id }: { id: string }) =>
  useUpdate<Partial<Pricing>, Pricing>(`${ApiEndpoints.pricing}/${id}`);
