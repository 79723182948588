import { ApiEndpoints } from "@/configs";
import { useFetch, usePost } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import { PaginatedRefills, Refill, formatDate } from "utilities";

export const useGetRefills = ({
  page,
  search,
  status = "",
  initialData,
  dateRange = [null, null],
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedRefills;
  status?: string;
  dateRange?: DateRange;
}) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    status,
  });
  return useFetch<PaginatedRefills>(`${ApiEndpoints.refills}`, params, {
    initialData,
  });
};

export const useGetRefill = (id: string) =>
  useFetch<Refill>(`${ApiEndpoints.refills}/${id}`);
export const useGetRefillDetail = (id: string) =>
  useFetch<Refill>(`${ApiEndpoints.refills}/${id}`);

export const useRecheckRefill = (refillPublicId: string) =>
  usePost<undefined, any>(`${ApiEndpoints.refills}/${refillPublicId}/recheck`);
