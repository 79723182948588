import { ApiEndpoints } from "@/configs";
import { useFetch, usePost, useUpdate } from "@/lib";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  Bundle,
  Currencies,
  DeleteBundleResponse,
  PaginatedBundles,
} from "utilities";

export const useGetBundles = ({
  page,
  search,
  currency,
  status,
  initialData,
}: {
  page?: number;
  search?: string;
  currency?: string;
  status?: boolean;
  initialData?: PaginatedBundles;
}) => {
  const params = buildFetchParams({
    page,
    search,
    currency,
    status,
  });

  return useFetch<PaginatedBundles>(`${ApiEndpoints.bundles}`, params, {
    initialData,
  });
};

export const useUpdateBundle = ({ id }: { id: string }) =>
  useUpdate<Partial<Bundle>, Bundle>(`${ApiEndpoints.bundles}/${id}`);

export const useAddBundle = () =>
  usePost<Partial<Bundle>, Bundle>(`${ApiEndpoints.bundles}`);

export const useDeleteBundle = (id: string) =>
  usePost<{ otp_code: string }, DeleteBundleResponse>(
    `${ApiEndpoints.bundles}/${id}/delete`,
  );

export const useUpdateBundleVisibility = ({ id }: { id: string }) =>
  useUpdate<Partial<Bundle>, Bundle>(`${ApiEndpoints.bundles}/${id}`);

export const useGetCurrencies = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Currencies>(`${ApiEndpoints.currencies}`, params);
};
