import {
  Discount,
  DiscountCodePayload,
  DiscountCodeResponse,
  PaginatedBundles,
  PaginatedDiscounts,
  PaginatedUsers,
} from "utilities";
import { buildFetchParams } from "@axazara/raiton-utils";
import { useFetch, usePost, useUpdate } from "@/lib";
import { ApiEndpoints } from "@/configs";

export const useGetDiscountsCode = ({
  page,
  search,
  status,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedDiscounts;
  status?: boolean;
}) => {
  const params = buildFetchParams({
    page,
    search,
    status,
  });
  return useFetch<PaginatedDiscounts>(ApiEndpoints.discounts, params, {
    initialData,
  });
};

export const useAddDiscountCode = () =>
  usePost<DiscountCodePayload, DiscountCodeResponse>(ApiEndpoints.discounts);

export const useUpdateDiscountCode = ({ id }: { id: string }) =>
  useUpdate<Partial<Discount>, Discount>(`${ApiEndpoints.discounts}/${id}`);

export const useDeleteDiscountCode = ({ id }: { id: string }) =>
  usePost<{ otp_code: string }, DiscountCodeResponse>(
    `${ApiEndpoints.discounts}/${id}/delete`,
  );

export const useUpdateDiscountCodeVisibility = ({ id }: { id: string }) =>
  useUpdate<Partial<Discount>, Discount>(`${ApiEndpoints.discounts}/${id}`);

export const useGetUsers = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedUsers>(ApiEndpoints.users, params);
};

export const useGetBundle = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedBundles>(ApiEndpoints.bundles, params);
};
