import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  LoadingOverlay,
  Surface,
  Tag,
  Text,
} from "@axazara/raiton-atoms";
import {
  Refresh2Outline,
  UserSearchOutline,
  ArrowCircleLeftOutline,
  RefreshOutline,
} from "@axazara/raiton-icons";
import { RefillStatus, formatDate } from "utilities";
import { showErrorNotification, showSuccessNotification } from "@/utils";
import { useGetRefill, useRecheckRefill } from "../../services";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSetPageMeta } from "@/hooks";
import { appPaths } from "@/configs";
import {
  CopyableInformationItem,
  InformationRow,
  RefillStatusBadge,
} from "@/components";

function RefillDetailsPageRender({ id }: { id: string }) {
  const navigate = useNavigate();

  const { data: refill, isError, isLoading } = useGetRefill(id);

  const useRecheckRefillMutation = useRecheckRefill(id);

  if (isLoading) return <LoadingOverlay visible />;

  async function handleRecheckRefill() {
    try {
      const response = await useRecheckRefillMutation.mutateAsync(undefined);
      showSuccessNotification((response as any).message);
    } catch (error) {
      showErrorNotification((error as any).message);
    }
  }
  if (isError) return <Navigate to={appPaths.refills.path} />;
  return (
    <Box className="pt-4">
      <Box className="flex items-center gap-2">
        <ActionIcon radius={100} onClick={() => navigate(-1)}>
          <ArrowCircleLeftOutline className="w-6 h-6" />
        </ActionIcon>
        <Box>
          <Text className="text-heading-05-bold">{refill?.id}</Text>
        </Box>
      </Box>
      <Box className="pt-4">
        <Box className="flex justify-end mb-6">
          {refill?.status === RefillStatus.PENDING_PAYMENT && (
            <Button
              variant="outline"
              size="xs"
              onClick={() => handleRecheckRefill()}
              rightIcon={
                useRecheckRefillMutation.isLoading ? (
                  <Refresh2Outline className="w-4 h-4 animate-spin" />
                ) : (
                  <Refresh2Outline className="w-4 h-4" />
                )
              }
            >
              Recheck
            </Button>
          )}
        </Box>
      </Box>
      <Box className="flex justify-between gap-x-5 mb-16">
        <Box className="w-full">
          <Box className="border border-neutral-80 rounded-xl p-4 mb-4">
            <CopyableInformationItem label="Id" value={refill?.id} />
            <InformationRow
              label="Status"
              value={refill && <RefillStatusBadge status={refill.status} />}
            />
            <InformationRow label="Amount" value={refill?.amount_formatted} />

            {refill?.created_at && (
              <InformationRow
                label="Created at"
                value={
                  refill?.created_at &&
                  formatDate(refill?.failed_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}

            {refill?.updated_at && (
              <InformationRow
                label="Updated at"
                value={
                  refill?.created_at &&
                  formatDate(refill?.updated_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}

            {refill?.completed_at && (
              <InformationRow
                label="Completed at"
                value={
                  refill?.completed_at &&
                  formatDate(refill?.completed_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}

            {refill?.failed_at && (
              <InformationRow
                label="Failed at"
                value={
                  refill?.failed_at &&
                  formatDate(refill?.failed_at, "en", "D MMM YYYY, HH:mm:ss")
                }
              />
            )}
          </Box>
          {refill?.failure_reason && (
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                Failed reasons
              </Text>
              <Box className="flex flex-row justify-between mb-4">
                <Text className="text-p-01-medium text-neutral-40">
                  {refill?.failure_reason}
                </Text>
              </Box>
            </Surface>
          )}

          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
              Bundle
            </Text>

            <InformationRow
              label="Coins"
              value={<Box>{refill?.bundle?.coins} coins</Box>}
            />
            <CopyableInformationItem label="Id" value={refill?.bundle.id} />
          </Surface>
        </Box>
        <Box className="w-full">
          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
              Payment
            </Text>

            <CopyableInformationItem
              label="Transaction id"
              value={refill?.payment.transaction_id}
            />
            <InformationRow label="Gateway" value={refill?.payment.gateway} />
            <InformationRow
              label="Status"
              value={
                <Tag
                  className="text-p-01-semibold normal-case bg-primary border border-primary-60 py-3"
                  size="lg"
                >
                  <Text
                    component="span"
                    className="text-primary-30 text-p-01-semibold"
                  >
                    {refill?.payment.status}
                  </Text>
                </Tag>
              }
            />
            <InformationRow
              label="Amount"
              value={refill?.payment?.amount_formatted}
            />
            <InformationRow
              label="Currency"
              value={refill?.payment?.currency}
            />
            <InformationRow
              label="Exchange rate"
              value={refill?.payment?.exchange_rate}
            />
            <InformationRow
              label="Correction rate"
              value={refill?.payment?.correction_rate}
            />
          </Surface>
          {refill?.metadata && (
            <Box className="mb-6 border border-neutral-80 rounded-xl p-4">
              <Text className="text-heading-06-sm-semibold text-neutral-40 mb-4">
                Metadata
              </Text>
              <Divider className="border-neutral-80 mb-6" />
              <Box className="space-y-4 ">
                <InformationRow
                  label="Payment gateway"
                  value={refill?.metadata?.payment_gateway}
                />
                <InformationRow
                  label="Payment Transaction Id"
                  value={refill?.metadata?.payment_transaction_id}
                />
                <InformationRow
                  label="Payment Transaction status"
                  value={refill?.metadata?.payment_transaction_status}
                />
              </Box>
            </Box>
          )}

          {refill?.discount_code && (
            <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
              <Text className="text-heading-06-sm-semibold text-neutral-40 mb-2">
                Discount code
              </Text>
              <CopyableInformationItem
                label="Id"
                value={refill?.discount_code?.id}
              />
              <CopyableInformationItem
                label="Code"
                value={refill?.discount_code?.code}
              />
            </Surface>
          )}

          <Surface radius={18} className="p-4 border border-neutral-80 mb-4">
            <Text className="text-heading-06-sm-semibold text-neutral-40 mb-2">
              User
            </Text>
            <Box className="flex items-center justify-between">
              <Box className="flex space-x-2 items-center">
                <Avatar src={refill?.user.profile_photo_url} size={48} />
                <Box>
                  <Text className="text-p-01-semibold mb-1">
                    {refill?.user.first_name} {refill?.user.last_name}
                  </Text>
                  <Text className="text-p-01-medium text-neutral-40 normal-case">
                    {refill?.user.email}
                  </Text>
                </Box>
              </Box>
              <Box component={Link} to={`/users/${refill?.user.id}`}>
                <Button
                  variant="outline"
                  color="primary.4"
                  size="sm"
                  rightIcon={<UserSearchOutline className="w-6" />}
                  className="text-p-01-semibold w-full md:w-auto"
                >
                  See profile
                </Button>
              </Box>
            </Box>
          </Surface>
        </Box>
      </Box>
    </Box>
  );
}

export function RefillDetails() {
  useSetPageMeta({
    title: "Refills",
    icon: RefreshOutline,
  });

  const { id } = useParams();

  if (!id) {
    return <Navigate to={appPaths.refills.path} />;
  }

  return <RefillDetailsPageRender id={id} />;
}
