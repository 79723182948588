import { ApiEndpoints } from "@/configs";
import { useFetch, usePost } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  Countries,
  Order,
  PaginatedOrders,
  RefundOrderPayload,
  RefundReasons,
  Services,
  formatDate,
} from "utilities";

export const useGetOrders = ({
  page = 1,
  search,
  initialData,
  dateRange = [null, null],
  status = "",
  country = "",
  service = "",
  provider_id = "",
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedOrders;
  dateRange?: DateRange;
  status?: string;
  service?: string;
  country?: string;
  provider_id?: string;
}) => {
  const requestedPage = search ? 1 : page;
  const [fromDate, toDate] = dateRange;

  let params = buildFetchParams({
    page: requestedPage,
    search,
    status,
    country,
    service,
    provider_id,
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
  });

  return useFetch<PaginatedOrders>(ApiEndpoints.orders, params, {
    initialData,
  });
};

export const useGetOrdersDetails = (id: string) =>
  useFetch<Order>(`${ApiEndpoints.orders}/${id}`);

export const useGetServices = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Services>(`${ApiEndpoints.servicesList}`, params);
};

export const useGetCountries = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Countries>(`${ApiEndpoints.countries}`, params);
};

export const useRefundOrder = ({ id }: { id: string }) =>
  usePost<RefundOrderPayload, { message: string }>(
    `${ApiEndpoints.orders}/${id}/refund`,
  );

export const useGetRefundOrderReasons = () =>
  useFetch<RefundReasons>(ApiEndpoints.refundReasons);
