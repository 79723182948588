import { ApiEndpoints } from "@/configs";
import { useFetch } from "@/lib";
import { DateRange } from "@/types";
import { buildFetchParams } from "@axazara/raiton-utils";
import {
  Countries,
  PaginatedOrders,
  PaginatedRefills,
  PaginatedTransactions,
  PaginatedUsers,
  Services,
  Summary,
  User,
  formatDate,
} from "utilities";

export const useGetUsers = ({
  page,
  search,
  initialData,
}: {
  page?: number;
  search?: string;
  initialData?: PaginatedUsers;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<PaginatedUsers>(`${ApiEndpoints.users}`, params, {
    initialData,
  });
};
export const useGetUser = (id: string) =>
  useFetch<User>(`${ApiEndpoints.users}/${id}`);
export const useGetUserOrders = (
  id: string,
  {
    page = 1,
    search,
    initialData,
    dateRange = [null, null],
    status = "",
    country = "",
    service = "",
    provider_id = "",
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedOrders;
    dateRange?: DateRange;
    status?: string;
    service?: string;
    country?: string;
    provider_id?: string;
  },
) => {
  const [fromDate, toDate] = dateRange;

  let params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    status,
    country,
    service,
    provider_id,
  });

  return useFetch<PaginatedOrders>(
    `${ApiEndpoints.users}/${id}/orders`,
    params,
    { initialData },
  );
};

export const useGetUserServices = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Services>(`${ApiEndpoints.services}`, params);
};

export const useGetUserCountries = ({
  page,
  search,
}: {
  page?: number;
  search?: string;
}) => {
  const params = buildFetchParams({
    page,
    search,
  });
  return useFetch<Countries>(`${ApiEndpoints.countries}`, params);
};

export const useGetUserTransactions = (
  id: string,
  {
    page,
    search,
    initialData,
    type = "",
    dateRange = [null, null],
  }: {
    page?: number;
    search?: string;
    type?: string;
    initialData?: PaginatedTransactions;
    dateRange?: DateRange;
  },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    type,
  });
  return useFetch<PaginatedTransactions>(
    `${ApiEndpoints.users}/${id}/transactions`,
    params,
    { initialData },
  );
};

export const useGetSummary = (id: string) =>
  useFetch<Summary>(`${ApiEndpoints.users}/${id}/summary`);

export const useGetUserRefills = (
  id: string,
  {
    page,
    search,
    status = "",
    initialData,
    dateRange = [null, null],
  }: {
    page?: number;
    search?: string;
    initialData?: PaginatedRefills;
    status?: string;
    dateRange?: DateRange;
  },
) => {
  const [fromDate, toDate] = dateRange;

  const params = buildFetchParams({
    from:
      fromDate && formatDate(fromDate.toDateString(), undefined, "YYYY-MM-DD"),
    to: toDate && formatDate(toDate.toDateString(), undefined, "YYYY-MM-DD"),
    page,
    search,
    status,
  });
  return useFetch<PaginatedRefills>(
    `${ApiEndpoints.users}/${id}/refills`,
    params,
    { initialData },
  );
};
