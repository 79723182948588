import { BaseEntity } from "./base";
import { Country } from "./country";
import { PaginatedData, PaginatedType } from "./pagination";
import { Pricing } from "./pricing";
import { Provider } from "./provider";
import { Service } from "./service";
import { SimplifiedUser, User } from "./user";

export interface Order extends BaseEntity {
  provider_order_id: string;
  phone_number: PhoneNumber;
  sms_received: Array<{ text: string; index: number; code: string }>;
  price: number;
  status: OrderStatus;
  pricing: Pricing;
  user: User;
  country: Country;
  completed_at: string;
  repurchase: { is_possible: boolean; price: number };
  expired_at: string;
  expire_at: string;
  failed_at: string;
  pending_at: string;
  refunded_at: string;
  refund_note: string;
  refund_reason: string;
  refunded_by: SimplifiedUser;
  service: Service;
  provider: Provider;
  failure_message: string;
  with_alt_provider: boolean;
}

export interface PhoneNumber {
  international: string;
  national: string;
}

export enum OrderStatus {
  INITIATED = "initiated",
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed",
  EXPIRED = "expired",
  REFUNDED = "refunded",
}

export interface RefundOrderPayload {
  refund_note: string;
  otp_code: string;
  refund_reason: string;
}
export type Orders = Order[];
export type PaginatedOrders = PaginatedType<PaginatedData, "data", Orders>;

export interface RefundReasons {
  label: string;
  value: string;
}
export interface OrderPayload {
  country_code: string;
  service_short_code: string;
}
export interface OrderResponse {
  order: Order;
  message: string;
}
