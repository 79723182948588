import { CopyableInformationItem } from "@/components";
import { Box, Divider, Surface, Text } from "@axazara/raiton-atoms";

export function OrderSms({ sms_received }: { sms_received: Array<any> }) {
  if (
    Object.keys(sms_received).every((key: any) => !sms_received[key] === null)
  )
    return null;

  return (
    <Box>
      <Surface className="p-4 border border-neutral-80 space-y-4 w-full mb-4">
        <Text className="text-heading-06-sm-semibold">SMS</Text>
        <Box className="space-y-4">
          <Surface className="p-4 border border-neutral-80 space-y-4 w-full">
            <Box className="flex flex-col gap-6">
              {Object.keys(sms_received).map((key: any) => {
                return (
                  <Box className="space-y-3">
                    <Box key={key}>{sms_received[key].text}</Box>
                    <CopyableInformationItem
                      label=""
                      value={sms_received[key].code}
                    />
                  </Box>
                );
              })}
            </Box>
          </Surface>
          <Divider color="neutral.9" className="border-neutral-90" />
        </Box>
      </Surface>
    </Box>
  );
}
